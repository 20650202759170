<template>
  <div class="index">
    <img src="@/assets/index/top-img.png" class="top-img" />
    <div class="main">
      <van-swipe class="my-swipe" indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item.url" />
        </van-swipe-item>
      </van-swipe>
      <div class="nav-list flex align between">
        <!-- <div class="item">
          <img src="@/assets/index/icon1.png" />
          <div>住宿安排</div>
        </div>
        <div class="item">
          <img src="@/assets/index/icon4.png" />
          <div>乘车安排</div>
        </div>
        <div class="item">
          <img src="@/assets/index/icon3.png" />
          <div>座次安排</div>
        </div> -->
        <div
          class="item"
          v-for="(item, index) in navList"
          :key="index"
          @click="toUrl3(index)"
        >
          <img :src="item.path" />
          <div>{{ item.title }}</div>
        </div>
      </div>
      <div class="video">
        <!-- <img src="@/assets/index/video_img.png" /> -->
        <video
          :src="videoUrl"
          :poster="videoImg"
          controls
          id="videoPlay"
        ></video>
      </div>
      <div class="flex align between navs2">
        <img src="@/assets/index/nav1.png" @click="toSch()" />
        <img src="@/assets/index/nav2.png" @click="toUrl('/classify')" />
      </div>
      <div class="code-div flex align between">
        <img src="@/assets/index/bg2.png" class="bg2" />
        <div class="code-item">
          <img
            src="@/assets/index/img.png"
            class="code2"
            style="margin-bottom: 0"
          />
          <!-- <div>2024年国际生物多样性</div> -->
        </div>
        <div class="code-item" @click="toHref()">
          <img src="@/assets/index/code1.png" class="code1" />
          <div>直播入口</div>
        </div>
      </div>
    </div>

    <img src="@/assets/index/bottom.png" class="bottom" />
  </div>
</template>
<script>
import axios from "axios";
import { Toast, Swipe, SwipeItem } from "vant";
import { position } from "@/api/apiData";
export default {
  components: {
    [Toast.name]: Toast,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    // 'china': {
    //   render(createElement) {
    //     return createElement(
    //       'script',
    //       {
    //         attrs: {
    //           type: 'text/javascript',
    //           src: 'http://showroom.nccloudmedia.com/uploads/js/20240507/dfb8628ab1d9ba3c89dd82391998b13e.js',//路径
    //         },
    //       },
    //     );
    //   },
    // }
  },
  data() {
    return {
      bannerList: [],
      videoImg: "",
      videoUrl: "",
      navList: [],
    };
  },
  mounted() {
    position.detail({ position_id: 2 }).then((res) => {
      if (res.code == 1) {
        this.bannerList = res.data.path;
      }
    });
    position.detail({ position_id: 3 }).then((res) => {
      if (res.code == 1) {
        this.videoImg = res.data.path;
      }
    });
    position.detail({ position_id: 1 }).then((res) => {
      if (res.code == 1) {
        this.videoUrl = res.data.path;
      }
    });
    position.lists({ position_id: 4 }).then((res) => {
      if (res.code == 1) {
        let arr = [];
        res.data.forEach((item) => {
          if (item.url != "") {
            item.url = item.url.split(",");
          }
          arr.push(item);
        });
        this.navList = arr;
      } else {
        Toast.fail(res.msg);
      }
    });
  },
  methods: {
    toUrl3(i) {
      if (this.navList[i].url != "") {
        let u = this.navList[i].url[0];
        let m = this.navList[i].url[1];
        let n = this.navList[i].url[2];
        this.$router.push({
          path: u,
          query: {
            type: m,
            id: n,
          },
        });
      }
    },
    toPlay() {
      var videoElement = document.getElementById("videoPlay");
      videoElement.currentTime = 2;
      if (videoElement.paused) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
    },
    toHref() {
      window.location.href = "https://auth.sinsam.com/live/tvchat-850575924";
    },
    toUrl(u) {
      this.$router.push(u);
    },
    toSch() {
      this.$router.push({
        path: "/publicity",
        query: {
          type: "日程安排",
          id: 13,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.top-img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.main {
  padding: 0 15px 80px;
  position: relative;
  top: 30px;
  .my-swipe {
    img {
      width: 100%;
      height: 180px;
      // object-fit: cover;
    }
  }
  .nav-list {
    margin: 10px 20px 20px;
    color: #27884f;
    font-size: 12px;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .video {
    img {
      width: 100%;
      border-radius: 12px;
    }
    video {
      width: 100%;
      border-radius: 12px;
      height: 180px;
      object-fit: cover;
    }
  }
  .navs2 {
    margin-top: 10px;
    img {
      width: 48%;
    }
  }
}
.bottom {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.code-div {
  // background: white;
  padding: 15px 45px 40px;
  margin-top: 20px;
  // box-shadow: 1px 1px 3px 1px gainsboro;
  border-radius: 10px;
  position: relative;
  .bg2 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .code-item {
    position: relative;
    color: #27884f;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    img {
      width: 90px;
      margin-bottom: 5px;
    }
    .code1 {
      width: 65px;
    }
    .code2 {
      margin-top: 5px;
    }
  }
}
</style>